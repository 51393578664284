import request from "@/utils/request";

/**
 * Если есть места со значением 0, нумеруем их от последнего места
 * например последнее место 20, есть 5 значений с место 0, добавляем 21,22,23,24,25
 * @param {*} params
 * @returns
 */
export async function GetIdeasRating(params) {
  const resp = await request({
    url: "/api/Player/IdeasRating/GetIdeasRating",
    method: "get",
    params,
  });

  // const resp = {
  //   data: [
  //     {
  //       id: 13,
  //       title: "Test",
  //       place: 1,
  //       votes: 0,
  //       author: "Family TestUser_0 Patronymic",
  //       hasVoted: false,
  //     },
  //     {
  //       id: 13,
  //       title: "Test",
  //       place: 0,
  //       votes: 0,
  //       author: "Family TestUser_0 Patronymic",
  //       hasVoted: false,
  //     },
  //     {
  //       id: 13,
  //       title: "Test",
  //       place: 0,
  //       votes: 0,
  //       author: "Family TestUser_0 Patronymic",
  //       hasVoted: false,
  //     },
  //     {
  //       id: 13,
  //       title: "Test",
  //       place: 0,
  //       votes: 0,
  //       author: "Family TestUser_0 Patronymic",
  //       hasVoted: false,
  //     },
  //   ],
  // };

  function max(list) {
    return list.reduce((a, b) => (a.place > b.place ? a.place : b.place));
  }

  try {
    if (resp?.data.length && resp.data.length > 1) {
      let maxValue = max(resp.data);
      maxValue++;

      resp.data.forEach((e) => {
        if (e.place === 0) {
          maxValue++;
          e.place = maxValue;
        }
      });
    } else if (resp.data.length === 1) {
      if (resp.data[0].place === 0) {
        resp.data[0].place = 1;
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  return resp;
}

export function GetIdeaDetails(params) {
  return request({
    url: "/api/Player/IdeasRating/GetIdeaDetails",
    method: "get",
    params,
  });
}

export function Vote(params) {
  return request({
    url: "/api/Player/IdeasRating/Vote",
    method: "post",
    params,
  });
}
